import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";

/**
 * makes sure the shopper can't choose identical patch and text color
 * by removing the identical color from the items (their options)
 */

const groupColors = (componentItems) => {
  const grippers = [];
  const patterns = []
  componentItems.forEach((item) => {
    if (item._id.includes("gripper")) {
      grippers.push(item);
    } else if (item._id.includes("pattern")) {
      patterns.push(item);
    }
  });
  const group = [
    ...(grippers.length > 0 && [{
      title: "Gripper Fabrics",
      reactComponent: "headingItem"
    }]) || [],
    ...grippers,
    ...(patterns.length > 0
      && [{ title: "Canvas Non-Gripper", reactComponent: "headingItem" }]) || [],
    ...patterns
  ]
  if (group.length)
    return group;
  return componentItems;
}

const useFilterColor = ({ componentItems, component, }) => {
  const itemsState = useAtomValue(items_state)
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);

  const filterItems = useCallback(
    (item) => {
      return componentItems.filter((i) => {
        return i._id !== item;
      });
    },
    [componentItems]
  );

  useEffect(() => {
    if (itemsState.activeIds["patch-material-front"]._id.split("-")[0] === itemsState.activeIds["customText-material-front"]._id.split("-")[0]) {
      let copy = { ...itemsState.activeIds };
      if (itemsState.activeIds["patch-material-front"]._id.split("-")[0] === "black") {
        copy["customText-material-front"] = { _id: "white-color-text" };
      } else {
        copy["customText-material-front"] = { _id: "black-color-text" };
      }
      setItemsActiveIds(copy)
    }
  }, [itemsState.activeIds, setItemsActiveIds])

  switch (component._id) {
    case "customText-material-front":
      const frontPatchTextColor = itemsState.activeIds["patch-material-front"]._id;
      const removeTextColor = frontPatchTextColor.replace("patch", "text");
      return itemsState.activeIds["custom-patch-front"]._id === "customImage-front-patch" ? componentItems : filterItems(removeTextColor);
    case "patch-material-front":
      const frontPatchColor = itemsState.activeIds["customText-material-front"]._id;
      const removeColorPatch = frontPatchColor.replace("text", "patch");
      return itemsState.activeIds["custom-patch-front"]._id === "customImage-front-patch" ? componentItems : filterItems(removeColorPatch);

    case "patch-material-sides":
      const sidePatchColor = itemsState.activeIds["customText-material-sides"]._id;
      const removeColorPatchSides = sidePatchColor.replace("text", "patch");
      return filterItems(removeColorPatchSides);

    case "customText-material-sides":
      const sidesPatchTextColor = itemsState.activeIds["patch-material-sides"]._id;
      const removeColorTextSides = sidesPatchTextColor.replace("patch", "text");
      return filterItems(removeColorTextSides);

    case "top-material":
    case "side-material":
    case "ribs-material-all":
    case "ribs-material-1":
    case "ribs-material-2":
    case "ribs-material-3":
    case "ribs-material-4":
    case "ribs-material-5":
    case "ribs-material-6":
      return groupColors(componentItems);

    default:
      return componentItems;
  }
};
export default useFilterColor;
