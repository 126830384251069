import React, { Children, useMemo } from "react";
import sift from "sift";
import { Line } from "../components/UI/Line/Line";
import { CreateReactComponent } from "../components/utils/CreateReactComponent";
import { useComponentsIndex } from "../hooks/useComponentsIndex";

const itemsCache = {};
const BaseScreen = ({ itemsStateArray, componentsState, productsState }) => {
  const { baseIndex } = useComponentsIndex(productsState);
  const baseComponents = useMemo(
    () =>
      componentsState.array
        .filter(
          sift({
            _id: {
              $in: productsState.activeObj.category[baseIndex].components,
            },
            $or: [{ excluded: { $exists: false } }, { excluded: false }],
          })
        )
        .sort((a, b) => {
          return productsState.activeObj.category[baseIndex].components.indexOf(a._id) - productsState.activeObj.category[baseIndex].components.indexOf(b._id);
        }),
    []
  );

  const getItems = (component) => {
    // check if query is already cached
    if (itemsCache[component._id]) {
      return itemsCache[component._id];
    }

    // query is not cached, so fetch it, sort it, and add it to the cache
    itemsCache[component._id] = itemsStateArray
      .filter(
        sift({
          _id: { $in: component.items },
          $or: [{ outOfStock: { $exists: false } }, { outOfStock: false }],
        })
      )
      .sort((a, b) => {
        return component.items.indexOf(a._id) - component.items.indexOf(b._id);
      });
    return itemsCache[component._id];
  };

  // const Description = () => {
  //   if (productsState.activeObj.shopify.strap && productsState.activeObj.shopify["gas-cap"]) {
  //     return <p className="text-center description">Comes with 18" strap and a gas cap cover</p>;
  //   }
  //   if (productsState.activeObj.shopify["gas-cap"]) {
  //     return <p className="text-center description">Includes a gas cap cover</p>;
  //   }
  //   if (productsState.activeObj.shopify.strap) {
  //     return <p className="text-center description">Comes with 18" strap</p>;
  //   }
  //   return null;
  // };

  return (
    <>
      {Children.toArray(
        baseComponents.map((component, index) => (
          <>
            <CreateReactComponent component={component} reactSchema={component.reactSchema} componentItems={getItems(component)} />
            {index !== baseComponents.length - 1 && <Line color="#AFAFAF" height="1px" width="85%" />}
          </>
        ))
      )}
    </>
  );
};

export default BaseScreen;
